import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the SectionsBuilder Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  sortables = {};

  connect () {
    super.connect()
    let controller = this;
    // components in section
    let sections = document.getElementsByClassName('section-container');
    for(let s of sections) {
      this.sortables[s.id] = new Sortable(s, {
        draggable: '.draggable',
        handle: '.draggable .drag-handle',
        mirror: {
          constrainDimensions: true
        }
      }).on('sortable:stop', function(ev){
        let data = {
          sec_index: ev.data.newContainer.parentElement.dataset.secIndex,
          oldIndex: ev.oldIndex,
          newIndex: ev.newIndex
        }
        controller.stimulate('PageConfig#sort_components', data)
        .then(function(result){
          // toastr.options.positionClass = "toast-bottom-right";
          // toastr.options.timeOut = 1000;
          // toastr.success('Salvato')
        });
      });
    }

    // sections in page
    var sections_sortable = new Sortable(document.getElementById('page-editor'), {
      draggable: '.draggable-section',
      handle: '.drag-section-handle',
      mirror: {
        constrainDimensions: true
      }
    });

    sections_sortable.on('sortable:start', function(ev){
      let index = ev.data.startIndex;
      document.querySelector('#addsection-'+index).style.visibility = 'hidden';
    });

    sections_sortable.on('sortable:stop', function(ev){
      
      let data = {
        oldIndex: ev.oldIndex,
        newIndex: ev.newIndex
      }
      controller.stimulate('PageConfig#sort_sections', data)
      .then(function(result){
        // toastr.options.positionClass = "toast-bottom-right";
        // toastr.options.timeOut = 1000;
        // toastr.success('Salvato')
        document.querySelector('#addsection-'+ev.oldIndex).style.visibility = 'visible';
      });
    });
  }

  finalizeReflex (element, reflex, noop, reflexId) {
    let controller = this;
    if(reflex == 'PageConfig#new_component'){
      // init new sortable
      let s = element.closest('.draggable-section').children[0];

      if(this.sortables[s.id]){
        this.sortables[s.id].destroy();
      }

      this.sortables[s.id] = new Sortable(s, {
        draggable: '.draggable',
        handle: '.draggable .drag-handle',
        mirror: {
          constrainDimensions: true
        }
      }).on('sortable:stop', function(ev){
        let data = {
          sec_index: ev.data.newContainer.parentElement.dataset.secIndex,
          oldIndex: ev.oldIndex,
          newIndex: ev.newIndex
        }
        controller.stimulate('PageConfig#sort_components', data)
        .then(function(result){
          // toastr.options.positionClass = "toast-bottom-right";
          // toastr.options.timeOut = 1000;
          // toastr.success('Salvato')
        });
      });
    }
  }
}
